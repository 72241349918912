import api from '../../api'
import WebStorage from '../share/WebStorage'
import Loader from '../share/Loader'
import config from '../../../public'
// import intbwnWorkflowsConfig from '../../router/utils/intbwnWorkflowsConfig'

export default {
  mixins: [
    Loader
  ],
  data () {
    return {
      gdprToAccept: false,
      checkLoginData: null,
      securityActions: null,
      securityActionsTemp: [],
      contractorApps: [],
      counter: 0,
      gdprContent: ''
    }
  },
  watch: {
    counter: function (newVal) {
      if (newVal === Object.keys(this.contractorApps).length) {
        this.$nextTick(function () {
          this.$events.$emit('dashboard:menu:userLogin')
          this.$events.$emit('dashboard:notification:loginSuccess')
        })
        this.redirectProperly(this.checkLoginData)
      }
    }
  },
  methods: {
    getCheckLoginMethod (username, password, gdprAccepted) {
      return gdprAccepted ? api.request('core', 'post', 'security/check-login', { username, password, gdprAccepted })
        : api.request('core', 'post', 'security/check-login', { username, password })
    },
    checkLogin (username, password, gdprAccepted) {
      this.toggleLoading()

      this.getCheckLoginMethod(username, password, gdprAccepted)
        .then((response) => {
          this.toggleLoading()
          const data = response.data

          this.checkLoginData = data
          this.contractorApps = data.data.contractorApps
          if (data.token) {
            const token = `Bearer ${data.token}`
            const refreshToken = data.refresh_token
            let user = data.user

            user.username = username
            this.$i18n.locale = user.locale ? user.locale : navigator.language

            user[`serviceClients`] = {}
            this.$store.commit('SET_USER', user)
            this.$store.commit('SET_TOKEN', token)
            this.$store.commit('SET_REFRESHTOKEN', refreshToken)
            WebStorage.setCredentials(user, token, refreshToken)
            WebStorage.setUser(user)
            WebStorage.setContractorApps(data.data.contractorApps)
            this.setSecurityActions(Object.keys(data.data.contractorApps), data)
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.hasOwnProperty('message') && error.response.data.message.includes('gdprToAccept:')) {
            this.$notify({
              type: 'info',
              text: this.$t('login.readGdpr')
            })
            this.gdprToAccept = true
            this.gdprContent = error.response.data.message.split('gdprToAccept:')[1]
            this.toggleLoading()
            return
          }
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: error.response.status === 401 ? this.$t('login.authErrorNotif') : this.getExpiredPasswordMessage(error.response.data.message)
          })
        })
    },
    getExpiredPasswordMessage(message) {
      return (message.split(': ')[0] === "#000941") ? this.$t('login.oldPasswordOnLoginNotif') : message
    },
    getSecurityActions (contractorApp) {
      // TODO: remove second case in the future (deprecated)
      let url = (this.$isWithClients(contractorApp) || contractorApp === 'finance') ? '/security/actions?sign-in=true' : '/security/action'
      api.request(contractorApp, 'get', url).then((response) => {
        this.handleResponse(response, true)
        this.counter += 1
      }).catch((error) => {
        this.counter += 1
        console.error(error)
      })
    },
    setSecurityActions (contractorApps, data) {
      for (let i = 0; i < contractorApps.length; i++) {
        if (this.$isWithClients(contractorApps[i])) {
          api.request(contractorApps[i], 'get', `/clients?userUuid=${data.user.uuid}`)
            .then((response) => {
              let clients = response.data
              let user = data.user
              user[`serviceClients`][contractorApps[i]] = clients
              this.$store.commit('SET_USER', user)
              WebStorage.setUser(user)
              this.getSecurityActions(contractorApps[i])
            }).catch((error) => {
              this.counter += 1
              console.log(error)
            })
        } else {
          this.getSecurityActions(contractorApps[i])
        }
      }
    },
    handleResponse (response, success) {
      let configBaseUrl = config.app.api.baseUrl
      let serviceBaseUrl = response.config.baseURL
      let service = Object.keys(configBaseUrl).filter(function (key) {
        return configBaseUrl[key] === serviceBaseUrl
      })[0]

      this.securityActions = this.securityActions !== null ? this.securityActions : {}
      if (success) {
        this.securityActions[service] = response.data
      } else {
        this.securityActions[service] = []
      }

      this.$store.commit('SET_SECURITY_ACTIONS', this.securityActions)
      WebStorage.setSecurityActions(this.securityActions)
    },
    redirectProperly (data) {
      if (data.firstLogin === true) {
        this.$router.push({ name: 'security_password_change' })
      } else if (this.$route.query.redirect !== undefined) {
        this.$router.push(this.$route.query.redirect)
      } else {
        this.$router.push('/')
      }
    }
  }
}
