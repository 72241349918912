<template>
  <div>
    <notifications width="470" />
    <h1> {{$t('login.passwordReset')}}</h1>
    <p>{{$t('login.newPassword')}}</p>
    <form @submit.prevent="onSubmitForm()">
      <input-type
        class="change-password-field"
        v-for="field in formFields"
        :key="field.name"
        v-model="model[field.name]"
        v-validate="{ rules: field.rules }"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : $t(field.label)"
        :error-message="vErrors.first(field.name)"
        :label="$t(field.label)"
        :name="field.name"
        :required="field.rules.required"
        :type="'password'"
      />
      <button
        type="submit"
        :class="'btn btn-primary btn-flat ' + (isLoading ? 'disabled' : '')"
        :disabled="isLoading"
      >
        {{$t('login.changePassword')}}
      </button>
    </form>
    <hr class="line-login">
    <router-link :to="{ name: 'security_login' }">
      {{$t('login.backToLogin')}}
    </router-link>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'
import InputType from '../../share/form/type/InputType'
import api from '../../../api'
import Authenticate from '../Authenticate'

export default {
  components: {
    InputType
  },
  mixins: [
    Authenticate
  ],
  data () {
    return {
      model: {
        password: '',
        password2: ''
      },
      formFields: [
        { name: 'password', label: 'login.password', rules: { required: true, password_form: true } },
        { name: 'password2', label: 'login.repeatPassword', rules: { required: true, repeated_password: true } }
      ],
      passwordUrl: `security/${this.$route.params.token}/password`
    }
  },
  created () {
    Validator.extend('repeated_password', {
      getMessage: () => this.$t('login.repeatedPasswordValidator'),
      validate: value => value === this.model.password
    })
    Validator.extend('password_form', {
      getMessage: () => this.$t('login.requiredPassworddValidator'),
      validate: value => (/[A-Z]+/.test(value) && /[0-9]+/.test(value) && /[!@#$%^&*()_+={}\\[\]:;"'<,>.?/-]+/.test(value))
    })
  },
  methods: {
    onSubmitForm () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')

          api.request('core', 'post', this.passwordUrl, { password: this.model.password })
            .then((response) => {
              this.toggleLoading()
              const username = response.data
              this.checkLogin(username, this.model.password)
            })
            .catch(() => {
              this.toggleLoading()
              this.$store.commit('TOGGLE_LOADING')
              this.$notify({
                type: 'error',
                text: this.$t('login.oldResetLink')
              })
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.line-login {
    border-color: #666;
}
.change-password-field {
  color: #fff;
}
</style>
